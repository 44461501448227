import "./OrderPage.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  clearCart,
  selectCart,
} from "../../features/shoppingCart/shoppingCartSlice";
import OrderItem from "../../Components/OrderItem/OrderItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import store from "../../app/store";
import axios from "axios";
import { refreshTheToken } from "../../features/user/userSlice";
import Select from "../../Components/Select/Select";

import Input from "../../Components/Input/Input";
import StreetsInput from "../../Components/StreetsInput/StreetsInput";
import ReactInputMask from "react-input-mask";
import LoginForm from "../../Components/LoginForm/LoginForm";
import { useNavigate } from "react-router-dom";
import { applyPromoCode } from "../../features/shoppingCart/shoppingCartSlice";
import moment from "moment";
import CitiesInput from "../../Components/CityInput/CityInput";

import { useTranslation } from "react-i18next";

const schema = yup.object({
  name: yup.string().required("ПІБ - це обов'язкове поле!"),

  phone_number: yup
    .string()
    .required("Номер телефону - це обов'язкове поле!")
    .matches(
      /^\+38\s\(0\d{2}\)\s\d{3}-\d{2}-\d{2}$/,
      "Номер телефону - це обов'язкове поле!"
    ),

  email: yup.string().email("Погано введені дані"),
});

const registeredUserSchema = yup.object({
  registered_name: yup
    .string()
    .required("ПІБ - це обов'язкове поле!")
    .default(true),

  registered_phone_number: yup
    .string()
    .required("Номер телефону - це обов'язкове поле!")
    .matches(
      /^\+38\s\(0\d{2}\)\s\d{3}-\d{2}-\d{2}$/,
      "Номер телефону - це обов'язкове поле!"
    ),

  registered_email: yup.string().default(true).email("Погано введені дані"),
});

const quickOrderSchema = yup.object({
  quickname: yup.string().required("ПІБ - це обов'язкове поле!"),

  quick_phone_number: yup
    .string()
    .required("Номер телефону - це обов'язкове поле!")
    .matches(
      /^\+38\s\(0\d{2}\)\s\d{3}-\d{2}-\d{2}$/,
      "Номер телефону - це обов'язкове поле!"
    ),
});

function OrderPage() {
  const { t } = useTranslation();

  const redirect = useNavigate();
  const { accessToken, user_id } = useSelector((state) => state.user);
  const [userData, setUserData] = useState(null);
  const [shippingPoint, setShippingPoint] = useState("");

  const handleShippingPointChange = (value) => {
    setShippingPoint(value);
  };

  useEffect(() => {
    if (accessToken) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            `https://svizhabe.herokuapp.com/user/${user_id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setUserData(response.data);
        } catch (error) {
          store.dispatch(refreshTheToken());
        }
      };

      fetchUserData();
    }
  }, [accessToken, user_id]);

  useEffect(() => {
    console.log(selectedCity);
    console.log(shippingPoint);
  });

  // Корзина
  const dispatch = useDispatch();
  const { cartItems, total, promoCode, discount } = useSelector(selectCart);
  // Корзина

  // Доставка
  const [deliveryMethod, setDeliveryMethod] = useState(t("OrderPage.Courier"));

  const handleDeliveryChange = (value) => {
    setDeliveryMethod(value);
  };

  const deliveryMethodsValues = {
    "Курʼєром по Києву (пн-пт)": "courier",
    "Самовивіз. м.Київ, вул. Євгена Сверстюка, 6В": "pickup",
    "Самовивіз. м.Київ, вул. Ніколаєва, 7": "pickup2",
    "Самовивіз. м.Київ, вул. Садова, 159-А, 65 ділянка": "pickup3",
    "Доставка Новою Поштою по Україні": "novaposhta",
  };

  const [selectedCity, setSelectedCity] = useState(t("orderPage.Kiev"));

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  const today = moment();
  const deliveryDateArr = [];
  const currentTimeInKiev = moment().utcOffset(2);

  let count;
  if (currentTimeInKiev.hour() >= 17) {
    count = 3; // Start with a 3-day delay
  } else {
    count = 2;
  }

  while (deliveryDateArr.length < 16) {
    const nextDate = today.clone().add(count, "days");
    const nextDayOfWeek = nextDate.day();

    if (nextDayOfWeek !== 0 && nextDayOfWeek !== 6) {
      deliveryDateArr.push(nextDate.format("DD-MM-YYYY"));
    }

    count++;
  }

  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(
    deliveryDateArr[0]
  );

  const handleDeliveryDateChange = (value) => {
    setSelectedDeliveryDate(value);
  };

  const [selectedDeliveryTime, setSelectedDeliveryTime] =
    useState("14:00-19:00");

  const handleDeliveryTimeChange = (value) => {
    setSelectedDeliveryTime(value);
  };
  // Доставка

  // Оплата
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    t("OrderPage.Cash")
  );
  const handlePaymentMethodChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const paymentMethodValues = {
    Готівкою: "cash",
    "Безготівковий розрахунок": "card",
    "Онлайн-оплата банківською карткою(LiqPay)": "liqpay",
    "Online payment by bank card (LiqPay)": "liqpay",
    "Online-Zahlung per Bankkarte (LiqPay)": "liqpay",
    "Оплата за реквізитами на карту": "cardProps",
    "Оплата через термінал у кур'єра": "card",
    "Zahlung per Kartendaten": "cardProps",
    "Payment by card details": "cardProps",
  };
  //

  // Мессенджер
  const [selectedMessenger, setSelectedMessenger] = useState("Telegram");

  const messengerNames = [
    { method: "Telegram", id: 1 },
    { method: "WhatsApp", id: 2 },
    { method: "Viber", id: 3 },
  ];

  const handleMessengerChange = (value) => {
    setSelectedMessenger(value);
  };

  const toggleMessenger = () => {
    const arrow = document.querySelector(".arrowmess");
    const messengerItems = document.querySelector(".messenger-items");
    if (arrow && messengerItems) {
      arrow.classList.toggle("activated");
      messengerItems.classList.toggle("activated");
    }
  };

  const chooseMessenger = (messenger) => {
    setSelectedMessenger(messenger);
    const messengerItems = document.querySelector(".messenger-items");
    const arrow = document.querySelector(".arrowmess");
    if (arrow && messengerItems) {
      arrow.classList.toggle("activated");
      messengerItems.classList.toggle("activated");
    }
  };
  // Мессенджер

  //Тип замовлення
  const [activeButton, setActiveButton] = useState("order");
  const [contentClass, setContentClass] = useState("order--content");
  //Тип замовлення

  // Промокод
  const [promo, setPromo] = useState("");
  const [promoError, setIsPromoError] = useState(false);
  const [isPromoApplied, setIsPromoApplied] = useState(false);

  const handlePromoCode = (e) => {
    e.preventDefault();

    if (isPromoApplied) {
      return;
    }

    const url = `https://svizhabe.herokuapp.com/promocode/${promo}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.active) {
          dispatch(applyPromoCode(response.data));
          setIsPromoApplied(true);
        } else {
          throw new Error("Промокод недійсний");
        }
      })
      .catch((error) => {
        console.error(error);
        setIsPromoError(true);
      });
  };

  // Промокод

  // Валідація

  const {
    register: registeredOrderRegister,
    handleSubmit: registeredHandleSubmit,
    formState: { errors: registeredOrderErrors },
    setValue,
  } = useForm({
    resolver: yupResolver(registeredUserSchema),
  });

  useEffect(() => {
    setValue("registered_name", userData?.first_name || "");
    setValue("registered_email", userData?.email || "");
  }, [setValue, userData]);

  const onRegisteredSubmit = (data) => {
    data["user"] = user_id;
    data["name"] = data["registered_name"];
    data["phone_number"] = data["registered_phone_number"];
    data["email"] = data["registered_email"];
    data["delivery_address"] = data["registered_delivery_address"];
    data["total_price"] = total + deliveryPrice;
    data["delivery_price"] = deliveryPrice;
    data["city"] = selectedCity;
    data["house"] = data["registered_house"];
    data["entrance"] = data["registered_entrance"];
    data["floor"] = data["registered_floor"];
    data["flat"] = data["registered_flat"];
    data["communication_preference"] = selectedMessenger.toLowerCase();
    data["delivery_method"] = deliveryMethodsValues[deliveryMethod];
    data["do_not_call"] = data["registered_do_not_call"];
    data["comment"] = data["registered_comment"];
    data["payment_method"] = paymentMethodValues[selectedPaymentMethod];
    data["delivery_date"] = selectedDeliveryDate;
    data["delivery_time"] = selectedDeliveryTime;
    data["promo_code"] = promoCode ? promoCode.id : null;
    data["discountNum"] = discount;
    data["shipping_point"] = shippingPoint;
    data["order_items"] = cartItems.map((item) => {
      return { product: item.id, amount: item.amount };
    });
    fetch("https://svizhabe.herokuapp.com/orders/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (data["payment_method"] === "liqpay") {
          const paymentData = {
            amount: total + deliveryPrice,
            description: `Оплата замовлення №${responseData.id}`,
            order_id: responseData.id,
          };

          fetch("https://svizhabe.herokuapp.com/payment/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(paymentData),
          })
            .then((paymentResponse) => paymentResponse.json())
            .then((paymentData) => {
              window.location.href = paymentData.payment_url;
            })
            .catch((paymentError) => {
              console.error("Error:", paymentError);
            });
        } else {
          return redirect("/success");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    dispatch(clearCart());
  };

  const {
    register: quickOrderRegister,
    handleSubmit: quickHandleSubmit,
    formState: { errors: quickOrderErrors },
  } = useForm({
    resolver: yupResolver(quickOrderSchema),
  });

  const onQuickSubmit = (data) => {
    data["name"] = data["quickname"];
    data["phone_number"] = data["quick_phone_number"];
    data["total_price"] = total;
    data["delivery_price"] = deliveryPrice;
    data["communication_preference"] = selectedMessenger.toLowerCase();
    data["promo_code"] = promoCode ? promoCode.id : null;
    data["discountNum"] = discount;

    data["order_items"] = cartItems.map((item) => {
      return { product: item.id, amount: item.amount };
    });
    fetch("https://svizhabe.herokuapp.com/orders/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return redirect("/success");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    dispatch(clearCart());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: handleSetValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data["promo_code"] = promoCode ? promoCode.id : null;
    data["discountNum"] = discount;
    data["delivery_method"] = deliveryMethodsValues[deliveryMethod];
    data["communication_preference"] = selectedMessenger.toLowerCase();
    data["payment_method"] = paymentMethodValues[selectedPaymentMethod];
    data["delivery_price"] = deliveryPrice;
    data["total_price"] = total + deliveryPrice;
    data["city"] = selectedCity;
    data["delivery_date"] = selectedDeliveryDate;
    data["delivery_time"] = selectedDeliveryTime;
    data["shipping_point"] = shippingPoint;
    data["order_items"] = cartItems.map((item) => {
      return { product: item.id, amount: item.amount };
    });

    fetch("https://svizhabe.herokuapp.com/orders/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (data["payment_method"] === "liqpay") {
          const paymentData = {
            amount: total + deliveryPrice,
            description: `Оплата замовлення №${responseData.id}`,
            order_id: responseData.id,
          };

          fetch("https://svizhabe.herokuapp.com/payment/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(paymentData),
          })
            .then((paymentResponse) => paymentResponse.json())
            .then((paymentData) => {
              window.location.href = paymentData.payment_url;
            })
            .catch((paymentError) => {
              console.error("Error:", paymentError);
            });
        } else {
          return redirect("/success");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    dispatch(clearCart());
  };
  // Валідація

  // const [deliveryPrice, setDeliveryPrice] = useState(150);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  useEffect(() => {
    if (total >= 2000 && deliveryMethod !== t("OrderPage.Delivery")) {
      setDeliveryPrice(0);
    } else if (contentClass === "quickorder--content") {
      setDeliveryPrice(150);
    } else if (deliveryMethod === t("OrderPage.Courier")) {
      setDeliveryPrice(150);
    } else if (deliveryMethod === t("OrderPage.Pickup")) {
      setDeliveryPrice(0);
    } else if (deliveryMethod === t("OrderPage.Pickup2")) {
      setDeliveryPrice(0);
    } else if (deliveryMethod === t("OrderPage.Pickup3")) {
      setDeliveryPrice(0);
    } else if (deliveryMethod === t("OrderPage.Delivery")) {
      setDeliveryPrice(100);
    }
  }, [deliveryMethod, total, contentClass]);

  return (
    <main>
      <div className="order--page--container">
        <h1 className="order--heading">{t("OrderPage.Placing_an_order")}</h1>
        <hr className="invisible" />
        <div className="order--user--buttons">
          <button
            className={activeButton === "order" ? "active" : ""}
            onClick={() => {
              setActiveButton("order");
              setContentClass("order--content");
              setDeliveryMethod(t("OrderPage.Courier"));
            }}
          >
            {t("OrderPage.I_am_a_new_buyer")}
          </button>

          <button
            className={activeButton === "registered-order" ? "active" : ""}
            onClick={() => {
              setActiveButton("registered-order");
              setContentClass("registered-order--content");
              setDeliveryMethod(t("OrderPage.Courier"));
            }}
          >
            {t("OrderPage.I_am_a_registered_buyer")}
          </button>

          <button
            className={activeButton === "quickorder" ? "active" : ""}
            onClick={() => {
              setActiveButton("quickorder");
              setContentClass("quickorder--content");
            }}
          >
            {t("OrderPage.Quick_order")}
          </button>
        </div>
        <div className="order--content">
          {/* Я новий покупець */}
          <form
            style={
              contentClass === "order--content"
                ? { display: "block" }
                : { display: "none" }
            }
            className="fields"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3 className="user--info">
              {t("OrderPage.Recipient_of_the_order")}
            </h3>

            <Input
              id="name"
              label={t("OrderPage.Full_name")}
              type="text"
              placeholder=""
              register={{ ...register("name") }}
              errorMessage={errors.name?.message}
            />

            <div className="input-group">
              <label
                htmlFor="phone_number"
                className={errors.phone_number?.message ? "redlabel" : ""}
              >
                {t("OrderPage.Phone_number")}
              </label>
              <div className="input-group-right">
                <ReactInputMask
                  mask="+38 (099) 999-99-99"
                  maskChar="_"
                  defaultValue="+38 (0__) ___-__-__"
                  placeholder="+38 (0__) ___-__-__"
                  className={errors.phone_number?.message ? "redform" : ""}
                  {...register("phone_number")}
                />
                <span className="error-message">
                  {errors.phone_number?.message}
                </span>
              </div>
            </div>

            <div className="forms--el">
              <label>{t("OrderPage.Where_would")}</label>
              <div
                className={
                  contentClass === "order--content"
                    ? "messenger-select"
                    : "disabled"
                }
              >
                <div className="chosen-messenger" onClick={toggleMessenger}>
                  <p>{selectedMessenger}</p>
                </div>
                <div
                  className={
                    contentClass === "order--content"
                      ? "messenger-items"
                      : "disabled"
                  }
                >
                  {messengerNames.map((messenger) => {
                    return (
                      <p
                        key={messenger.id}
                        onClick={() => chooseMessenger(messenger.method)}
                      >
                        {messenger.method}
                      </p>
                    );
                  })}
                </div>
                <div
                  className={
                    contentClass === "order--content" ? "arrowmess" : "disabled"
                  }
                  onClick={toggleMessenger}
                >
                  <div className="arrow-wrapper">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>

            <Input
              id="email"
              label={t("OrderPage.email")}
              type="text"
              placeholder=""
              register={{ ...register("email") }}
              errorMessage={errors.email?.message}
            />

            <Input
              id="comment"
              label={t("OrderPage.comment")}
              type="text"
              placeholder={t("OrderPage.You_can")}
              register={{ ...register("comment") }}
            />

            <Input
              className="callMe"
              id="do_not_call"
              label={t("OrderPage.Dont_call")}
              type="checkbox"
              placeholder=""
              register={{ ...register("do_not_call") }}
            />

            <hr className="rozdiliti" />
            <h3 className="user--info">{t("footer.Delivery")}</h3>

            <Select
              selectId="delivery_method"
              label={t("OrderPage.Way")}
              arr={[
                t("OrderPage.Courier"),
                t("OrderPage.Pickup"),
                t("OrderPage.Pickup2"),
                t("OrderPage.Pickup3"),
                t("OrderPage.Delivery"),
              ]}
              onChange={handleDeliveryChange}
            />

            <div
              style={
                deliveryMethod === t("OrderPage.Delivery")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              className="delivery_policy_order"
            >
              <h4>{t("footer.Delivery")}</h4>
              <p>
                {t("OrderPage.Minimal_order")} - 2000 {t("CartItem.price")}
              </p>
              <p>{t("OrderPage.Shipping_only")}</p>
              <p>{t("OrderPage.The_cost")}</p>
            </div>

            {deliveryMethod === t("OrderPage.Delivery") && (
              <CitiesInput
                id="city"
                label={t("OrderPage.City")}
                type="text"
                register={{ ...register("city") }}
                errorMessage={errors.city?.message}
                setValue={handleCityChange}
                handleShippingPointChange={handleShippingPointChange}
              />
            )}

            <div
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              className="delivery_policy_order"
            >
              <h4>{t("BuyUsPage.Shipping_cost")}</h4>
              <p>{t("BuyUsPage.Kiev")}</p>
              <p>{t("BuyUsPage.Orders")}</p>
            </div>

            <Select
              selectId="city"
              label={t("OrderPage.City")}
              arr={[t("OrderPage.Kiev")]}
              onChange={handleCityChange}
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
            />

            <div
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <StreetsInput
                id="delivery_address"
                label={t("OrderPage.Street")}
                type="text"
                placeholder={t("OrderPage.exmpl")}
                register={{ ...register("delivery_address") }}
                errorMessage={errors.delivery_address?.message}
                setValue={handleSetValue}
              />
            </div>

            <Input
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              id="house"
              label={t("OrderPage.House")}
              type="text"
              placeholder=""
              register={{ ...register("house") }}
              errorMessage={errors.house?.message}
            />

            <Input
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              id="entrance"
              label={t("OrderPage.Entrance")}
              type="text"
              placeholder=""
              register={{ ...register("entrance") }}
            />

            <Input
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              id="floor"
              label={t("OrderPage.Floor")}
              type="text"
              placeholder=""
              register={{ ...register("floor") }}
            />

            <Input
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              id="flat"
              label={t("OrderPage.Apartment")}
              type="text"
              placeholder=""
              register={{ ...register("flat") }}
            />

            <Select
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              selectId="delivery_date"
              label={t("OrderPage.Date")}
              arr={deliveryDateArr}
              onChange={handleDeliveryDateChange}
            />

            <Select
              style={
                deliveryMethod === t("OrderPage.Courier")
                  ? { display: "flex" }
                  : { display: "none" }
              }
              selectId="delivery_time"
              label={t("OrderPage.Estimated")}
              // arr={
              //   selectedDeliveryDate === today.format("DD-MM-YYYY")
              //     ? ["12:00-17:00"]
              //     : ["8:00-10:00", "12:00-17:00"]
              // }
              arr={["14:00-19:00"]}
              onChange={handleDeliveryTimeChange}
            />

            <hr className="rozdiliti" />
            <h3 className="user--info">{t("OrderPage.Payment")}</h3>

            <Select
              selectId="order_payment_method"
              label={t("OrderPage.Way")}
              arr={[
                t("OrderPage.Cash"),
                t("OrderPage.Online"),
                // t("OrderPage.CardProps")
                // "Оплата через термінал у кур'єра",
              ]}
              onChange={handlePaymentMethodChange}
            />

            <div className="input-group" style={{ "margin-bottom": "20px" }}>
              <label>{t("OrderPage.Promo")}</label>
              <div className="input-group-right promobutton">
                <input
                  id="promocode"
                  placeholder="XXXXXXXXX"
                  type="text"
                  value={promo}
                  onChange={(e) => setPromo(e.target.value)}
                />

                <button
                  style={promoCode ? { display: "none" } : { display: "block" }}
                  onClick={handlePromoCode}
                  className="activate-promo"
                >
                  {t("OrderPage.to_apply")}
                </button>
              </div>
            </div>
            <span
              style={promoError ? { display: "block" } : { display: "none" }}
              className="error-message"
            >
              Промо-код введено неправильно!
            </span>
            <span
              style={promoCode ? { display: "block" } : { display: "none" }}
              className="blue-message"
            >
              Промо-код застосовано!
            </span>

            <button
              id="pageorderbuy"
              className="wannaorder"
              style={total > 0 ? { display: "block" } : { display: "none" }}
            >
              {t("OrderPage.Confirm_the_order")}
            </button>
          </form>
          {/* Я новий покупець */}

          {/* Швидке замовлення */}
          <form
            style={
              contentClass === "quickorder--content"
                ? { display: "block" }
                : { display: "none" }
            }
            className="fields"
            onSubmit={quickHandleSubmit(onQuickSubmit)}
          >
            <h3 className="user--info">
              {t("OrderPage.Recipient_of_the_order")}
            </h3>

            <Input
              id="quickname"
              label={t("OrderPage.Full_name")}
              type="text"
              placeholder=""
              register={{ ...quickOrderRegister("quickname") }}
              errorMessage={quickOrderErrors.quickname?.message}
            />

            <div className="input-group">
              <label
                htmlFor="quick_phone_number"
                className={
                  quickOrderErrors.quick_phone_number?.message ? "redlabel" : ""
                }
              >
                {t("OrderPage.Phone_number")}
              </label>
              <div className="input-group-right">
                <ReactInputMask
                  mask="+38 (099) 999-99-99"
                  maskChar="_"
                  defaultValue="+38 (0__) ___-__-__"
                  placeholder="+38 (0__) ___-__-__"
                  {...quickOrderRegister("quick_phone_number")}
                  className={
                    quickOrderErrors.quick_phone_number?.message
                      ? "redform"
                      : ""
                  }
                />
                <span className="error-message">
                  {quickOrderErrors.quick_phone_number?.message}
                </span>
              </div>
            </div>

            <div className="forms--el">
              <label>{t("OrderPage.Where_would")}</label>
              <div className="messenger-select">
                <div className="chosen-messenger" onClick={toggleMessenger}>
                  <p>{selectedMessenger}</p>
                </div>
                <div className="messenger-items">
                  {messengerNames.map((messenger) => {
                    return (
                      <p
                        key={messenger.id}
                        onClick={() => chooseMessenger(messenger.method)}
                      >
                        {messenger.method}
                      </p>
                    );
                  })}
                </div>
                <div className="arrowmess" onClick={toggleMessenger}>
                  <div className="arrow-wrapper">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="input-group" style={{ "margin-bottom": "20px" }}>
              <label>{t("OrderPage.Promo")}</label>
              <div className="input-group-right promobutton">
                <input
                  id="promocode"
                  placeholder="XXXXXXXXX"
                  type="text"
                  value={promo}
                  onChange={(e) => setPromo(e.target.value)}
                />

                <button
                  style={promoCode ? { display: "none" } : { display: "block" }}
                  onClick={handlePromoCode}
                  className="activate-promo"
                >
                  {t("OrderPage.to_apply")}
                </button>
              </div>
            </div>

            <span
              style={promoError ? { display: "block" } : { display: "none" }}
              className="error-message"
            >
              Промо-код введено неправильно!
            </span>
            <span
              style={promoCode ? { display: "block" } : { display: "none" }}
              className="blue-message"
            >
              Промо-код застосовано!
            </span>

            <p className="inf">{t("OrderPage.Our_operator")}</p>

            <button
              id="pagequickorderbuy"
              className="wannaorder"
              style={total > 0 ? { display: "block" } : { display: "none" }}
            >
              {t("main.I_want_to_order")}
            </button>
          </form>
          {/* Швидке замовлення */}

          {/* Я зареєстрований */}
          <div
            style={
              contentClass === "registered-order--content"
                ? { display: "flex" }
                : { display: "none" }
            }
            className="registered-order--content"
          >
            {accessToken ? (
              <form
                className="fields"
                onSubmit={registeredHandleSubmit(onRegisteredSubmit)}
              >
                <h3 className="user--info">
                  {t("OrderPage.Recipient_of_the_order")}
                </h3>
                <Input
                  id="registered_name"
                  label={t("OrderPage.Full_name")}
                  type="text"
                  placeholder=""
                  defaultValue={userData?.first_name}
                  register={{ ...registeredOrderRegister("registered_name") }}
                  errorMessage={registeredOrderErrors.registered_name?.message}
                />
                <div className="input-group">
                  <label
                    htmlFor="registered_phone_number"
                    className={
                      registeredOrderErrors.registered_phone_number?.message
                        ? "redlabel"
                        : ""
                    }
                  >
                    {t("OrderPage.Phone_number")}
                  </label>
                  <div className="input-group-right">
                    <ReactInputMask
                      mask="+38 (099) 999-99-99"
                      maskChar="_"
                      defaultValue="+38 (0__) ___-__-__"
                      placeholder="+38 (0__) ___-__-__"
                      className={
                        registeredOrderErrors.registered_phone_number?.message
                          ? "redform"
                          : ""
                      }
                      {...registeredOrderRegister("registered_phone_number")}
                    />
                    <span className="error-message">
                      {registeredOrderErrors.registered_phone_number?.message}
                    </span>
                  </div>
                </div>

                <Select
                  selectId="registered_messenger"
                  label={t("OrderPage.Where_would")}
                  arr={["Telegram", "Viber", "WhatsApp"]}
                  onChange={handleMessengerChange}
                />

                <Input
                  id="registered_email"
                  label="Е-пошта"
                  type="text"
                  placeholder=""
                  defaultValue={userData?.email}
                  register={{ ...registeredOrderRegister("registered_email") }}
                  errorMessage={registeredOrderErrors.registered_email?.message}
                />
                <Input
                  id="registered_comment"
                  label="Коментар"
                  type="text"
                  placeholder="Ви можете залишити тут коментар"
                  register={{
                    ...registeredOrderRegister("registered_comment"),
                  }}
                />

                <Input
                  className="callMe"
                  id="registered_do_not_call"
                  label={t("OrderPage.Dont_call")}
                  type="checkbox"
                  placeholder=""
                  register={{ ...register("registered_do_not_call") }}
                />
                <hr className="rozdiliti" />
                <h3 className="user--info">{t("footer.Delivery")}</h3>

                <Select
                  selectId="registered_delivery_method"
                  label={t("OrderPage.Way")}
                  arr={[
                    t("OrderPage.Courier"),
                    t("OrderPage.Pickup"),
                    t("OrderPage.Pickup2"),
                    t("OrderPage.Pickup3"),
                    t("OrderPage.Delivery"),
                  ]}
                  onChange={handleDeliveryChange}
                />

                <div
                  style={
                    deliveryMethod === t("OrderPage.Delivery")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  className="delivery_policy_order"
                >
                  <h4>{t("footer.Delivery")}</h4>
                  <p>Мінімальне замовлення - 2000 {t("CartItem.price")}</p>
                  <p>Відправка лише після повної оплати</p>
                  <p>Вартість доставки за тарифами Нової Пошти</p>
                </div>

                {deliveryMethod === t("OrderPage.Delivery") && (
                  <CitiesInput
                    id="city"
                    label={t("OrderPage.City")}
                    type="text"
                    register={{ ...register("city") }}
                    errorMessage={errors.city?.message}
                    setValue={(val) => handleCityChange(val)}
                    handleShippingPointChange={handleShippingPointChange}
                  />
                )}

                <div
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  className="delivery_policy_order"
                >
                  <h4>{t("BuyUsPage.Shipping_cost")}</h4>
                  <p>{t("BuyUsPage.Kiev")}</p>
                  <p>{t("BuyUsPage.Orders")}</p>
                </div>

                <Select
                  selectId="registered_city"
                  label={t("OrderPage.City")}
                  arr={[t("OrderPage.Kiev")]}
                  onChange={handleCityChange}
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                />

                <div
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                >
                  <StreetsInput
                    id="registered_delivery_address"
                    label={t("OrderPage.Street")}
                    type="text"
                    placeholder={t("OrderPage.exmpl")}
                    register={{
                      ...registeredOrderRegister("registered_delivery_address"),
                    }}
                    errorMessage={
                      registeredOrderErrors.registered_delivery_address?.message
                    }
                    setValue={setValue}
                  />
                </div>

                <Input
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  id="registered_house"
                  label={t("OrderPage.House")}
                  type="text"
                  placeholder=""
                  register={{ ...registeredOrderRegister("registered_house") }}
                  errorMessage={registeredOrderErrors.registered_house?.message}
                />

                <Input
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  id="registered_entrance"
                  label={t("OrderPage.Entrance")}
                  type="text"
                  placeholder=""
                  register={{ ...register("registered_entrance") }}
                />

                <Input
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  id="registered_floor"
                  label={t("OrderPage.Floor")}
                  type="text"
                  placeholder=""
                  register={{ ...register("registered_floor") }}
                />

                <Input
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  id="registered_flat"
                  label={t("OrderPage.Apartment")}
                  type="text"
                  placeholder=""
                  register={{ ...register("registered_flat") }}
                />

                <Select
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  selectId="registered_delivery_date"
                  label={t("OrderPage.Date")}
                  arr={deliveryDateArr}
                  onChange={handleDeliveryDateChange}
                />

                <Select
                  style={
                    deliveryMethod === t("OrderPage.Courier")
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  selectId="registered_delivery_time"
                  label={t("OrderPage.Estimated")}
                  // arr={
                  //   selectedDeliveryDate === today.format("DD-MM-YYYY")
                  //     ? ["12:00-17:00"]
                  //     : ["8:00-10:00", "12:00-17:00"]
                  // }
                  arr={["14:00-19:00"]}
                  onChange={handleDeliveryTimeChange}
                />

                <hr className="rozdiliti" />
                <h3 className="user--info">{t("OrderPage.Payment")}</h3>

                <Select
                  selectId="registered_order_payment_method"
                  label={t("OrderPage.Way")}
                  arr={[t("OrderPage.Cash"), t("OrderPage.Online")]}
                  onChange={handlePaymentMethodChange}
                />

                <div
                  className="input-group"
                  style={{ "margin-bottom": "20px" }}
                >
                  <label>{t("OrderPage.Promo")}</label>
                  <div className="input-group-right promobutton">
                    <input
                      id="promocode"
                      placeholder="XXXXXXXXX"
                      type="text"
                      value={promo}
                      onChange={(e) => setPromo(e.target.value)}
                    />

                    <button
                      style={
                        promoCode ? { display: "none" } : { display: "block" }
                      }
                      onClick={handlePromoCode}
                      className="activate-promo"
                    >
                      {t("OrderPage.to_apply")}
                    </button>
                  </div>
                </div>

                <span
                  style={
                    promoError ? { display: "block" } : { display: "none" }
                  }
                  className="error-message"
                >
                  Промо-код введено неправильно!
                </span>
                <span
                  style={promoCode ? { display: "block" } : { display: "none" }}
                  className="blue-message"
                >
                  Промо-код застосовано!
                </span>

                <button
                  id="pageregisteredorderbuy"
                  className="wannaorder"
                  style={total > 0 ? { display: "block" } : { display: "none" }}
                >
                  {t("OrderPage.Confirm_the_order")}
                </button>
              </form>
            ) : (
              <LoginForm />
            )}
          </div>
          {/* Я зареєстрований */}

          <div className="order--items">
            <h3 className="user--info">{t("OrderPage.your_order")}</h3>
            {cartItems.map((item) => {
              return <OrderItem key={item.id} {...item} />;
            })}

            <hr
              style={promoCode ? { display: "block" } : { display: "none" }}
              className="grey"
            />

            <div
              style={promoCode ? { display: "flex" } : { display: "none" }}
              className="delivery"
            >
              <span>Знижка</span>
              <p>
                {discount} {t("CartItem.price")}
              </p>
            </div>

            <hr className="grey" />
            <div className="delivery">
              <span>{t("ShoppingCart.total")}</span>
              <p>
                {total} {t("CartItem.price")}
              </p>
            </div>
            <hr className="grey" />

            <div className="delivery">
              <span>
                {deliveryMethod === t("OrderPage.Delivery")
                  ? t("OrderPage.Packing")
                  : t("footer.Delivery")}
              </span>
              <p>
                {deliveryPrice} {t("CartItem.price")}
              </p>
            </div>

            <hr className="grey" />
            <div className="summary">
              <span>{t("OrderPage.To_be_paid")}:</span> {total + deliveryPrice}{" "}
              {t("CartItem.price")}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default OrderPage;
